<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ title }}
        </h2>
        <p class="mb-2">
          {{ description }}
        </p>

        <div v-if="failedPermissions" class="mb-2">
          <ul v-for="permission in failedPermissions" :key="permission">
            <li class="font-weight-bolder">
              {{ permission }}
            </li>
          </ul>
        </div>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="homePath"
        >
          Back to home
        </b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'
import { errorsType } from '@/services/errors'
import router, { path } from '@/router'

export default {
  name: 'AbstractError',
  components: { BButton, BImg },
  setup () {
    const errorType = router.currentRoute.meta.type
    const failedPermissions = router.currentRoute.params.failedPermissions?.split(',') || null

    const errorsInfo = {
      [errorsType.forbidden]: {
        title: '403 Forbidden 🕵🏻‍♀️',
        description: 'Oops! 😖 You does not have permission :'
      },
      [errorsType.notFound]: {
        title: 'Page Not Found 🕵🏻‍♀️',
        description: 'Oops! 😖 The requested URL was not found.'
      }
    }

    const { title, description } = errorsInfo[errorType]

    return {
      title,
      description,
      failedPermissions,
      homePath: path.home
    }
  },
  computed: {
    imgUrl () {
      if (store.state.appConfig.layout.skin === 'dark') {
        return require('@/assets/images/pages/error-dark.svg')
      }
      return require('@/assets/images/pages/error.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
$misc-inner-max-width: 750px;

.misc-wrapper {
  display: flex;
  flex-basis: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  .misc-inner {
    position: relative;
    max-width: $misc-inner-max-width;
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 2rem;
    left: 2rem;
    margin: 0;
    .brand-text {
      font-weight: 600;
    }
    svg {
      height: 28px;
      width: 40.95px;
    }
  }
}
</style>
